import { Location } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta, SafeHtml, DomSanitizer } from '@angular/platform-browser';
import {
  ProductCategory,
  ProductSubCategory,
} from 'src/app/models/productcategory';
import { BlogRssService } from 'src/app/services/blog-rss.service';
import { ProductCategoryService } from 'src/app/services/productcategory.service';
import {
  CATEGORY_URL_TO_BLOG_CATEGORY,
  STATIC_REVIEWS_BY_CATEGORY,
} from './static_content';
import { Subscription } from 'rxjs';
import { Big } from 'big.js';
import { OgConfigSeo } from 'src/app/models/seo/og-seo-config';
import { OgSeoService } from 'src/app/services/seo/og-seo.service';
import { isPlatformBrowser } from '@angular/common';
import { Product } from 'src/app/models/products';
import { productsToServices, enleySchema } from 'src/app/schemaOrg';
import { HeadService } from 'src/app/services/head.service';

const DEFAULT_CATEGORY_DESCRIPTION =
  'Contáctanos sin compromiso para obtener asistencia legal. Contamos con un equipo de abogados especialistas que pueden ayudarte y guiarte en los procesos legales.';

@Component({
  selector: 'app-productcategory',
  templateUrl: './productcategory.component.html',
  styleUrls: ['./productcategory.component.css'],
  providers: [HeadService],
})
export class ProductCategoryComponent implements OnInit, OnDestroy {
  constructor(
    private productCategoryService: ProductCategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogRssService,
    private sanitizer: DomSanitizer,
    private ogSeoService: OgSeoService,
    private headService: HeadService,
    private renderer: Renderer2,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  categoryUrl: string = null;
  type: string = null;
  htmlContent: SafeHtml = null;

  category: ProductCategory = null;
  subcategories: ProductSubCategory[];
  phone_number: string = '';

  articles = [];
  reviews = [];
  canvasHeight: string = null;
  private routeSubscription: Subscription;

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.initializeComponent();
    });
  }

  fetchHtmlForItems() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.category.header_html) {
        fetch(this.category.header_html)
          .then((response) => response.text())
          .then((html) => {
            this.htmlContent = this.sanitizeHtml(html);
            const canvasHeightMatch = html.match(
              /<canvas[^>]*height="(\d+)"[^>]*>/
            );
            this.canvasHeight = canvasHeightMatch ? canvasHeightMatch[1] : null;
          })
          .catch((error) => console.error('Error fetching the HTML:', error));
      }
    }
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  private initializeComponent(): void {
    this.categoryUrl = this.route.snapshot.params.categoryUrl;
    if (
      this.route.snapshot.url.join().split(',')[0] ===
      'servicios-para-tu-empresa'
    ) {
      this.type = 'b2b';
    } else {
      this.type = 'b2c';
    }

    this.getCategory();
    this.getArticles();
    this.getReviews();
  }

  addServicesToHtmlHead(products: Product[]) {
    const head = this.headService.forRenderer(this.renderer);
    const services = [...productsToServices(products, this.location)];
    head.createElement('script', {
      type: 'application/ld+json',
      [HeadService.ELEMENT_CONTENT]: JSON.stringify(enleySchema(services)),
    });
  }

  getCategory() {
    this.productCategoryService
      .fetchProductCategory(this.categoryUrl, this.type)
      .subscribe(
        (category) => {
          this.category = category;
          this.subcategories = category.subcategories;
          this.addServicesToHtmlHead(category.products);
          this.fetchHtmlForItems();
          if (
            this.category.department &&
            this.category.department.phone_number
          ) {
            this.phone_number = this.category.department.phone_number;
          }
          this.category.title =
            this.category.title && this.category.title != ''
              ? this.category.title
              : this.category.name;

          this.setOgSeo();

          this.category.description =
            this.category.description && this.category.description != ''
              ? this.category.description
              : DEFAULT_CATEGORY_DESCRIPTION;

          this;
          if (this.category.subcategories) {
            this.category.subcategories.forEach((subcategory) => {
              subcategory.products.forEach((product) => {
                if (product.product_description.price_text) {
                  const formatPrice = (price: number): string => {
                    return Big(price)
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                  };
                  this.category.subcategories.forEach((subcategory) => {
                    subcategory.products.forEach((product) => {
                      if (
                        product.product_description.price_text &&
                        typeof product.product_description.price_text ===
                          'string'
                      ) {
                        product.product_description.price_text_html =
                          this.sanitizer.bypassSecurityTrustHtml(
                            product.product_description.price_text
                              .replace(/{{price}}/, formatPrice(product.price))
                              .replace(
                                /\{{price_without_iva}}/gi,
                                (product.price / 1.21).toLocaleString()
                              )
                              .replace(
                                '€',
                                '<span style="font-size:15px">&#8364;</span>'
                              )
                              .replace(/\./g, '')
                          );
                      }
                    });
                  });
                }
              });
            });
          }
          if (this.category.products) {
            this.category.products.forEach((product) => {
              if (
                product.product_description.price_text &&
                typeof product.product_description.price_text === 'string'
              ) {
                const formatPrice = (price: number): string => {
                  return Big(price)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                };

                product.product_description.price_text_html =
                  this.sanitizer.bypassSecurityTrustHtml(
                    product.product_description.price_text
                      .replace(/{{price}}/, formatPrice(product.price))
                      .replace(
                        /\{{price_without_iva}}/gi,
                        (product.price / 1.21).toLocaleString()
                      )
                      .replace(/\./g, '')

                      .replace(
                        '€',
                        '<span style="font-size:15px">&#8364;</span>'
                      )
                  );
              }
            });
          }
        },
        (error) => {
          this.router.navigate(['/pagina-no-encontrada']);
        }
      );
  }

  getArticles() {
    const blogCategory = CATEGORY_URL_TO_BLOG_CATEGORY.get(this.categoryUrl);
    this.blogService.getPostsByCategory(blogCategory).subscribe(
      (articles) => {
        this.articles = articles;
      },
      (error) => {
        this.articles = [];
      }
    );
  }

  getReviews() {
    this.reviews = STATIC_REVIEWS_BY_CATEGORY.get(this.categoryUrl) || [];
  }

  setOgSeo() {
    console.log(this.category);

    const ogSeoConfig: OgConfigSeo = {
      title: this.category.meta_title,
      description: this.category.meta_description,
    };

    this.ogSeoService.setOgSeoTitleAndDescription(ogSeoConfig);
  }
}
