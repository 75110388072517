import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { ScreenSizeService } from 'src/app/services/screen-size.service';

@Component({
  selector: 'app-services-for-you',
  templateUrl: './services-for-you.component.html',
  styleUrls: ['./services-for-you.component.css'],
  animations: [
    trigger('widthGrow', [
      state(
        'hide',
        style({
          height: 300,
        })
      ),
      state(
        'show',
        style({
          height: '*',
          width: 350,
          marginTop: -40,
        })
      ),
      transition('hide <=> show', animate('300ms ease-in-out')),
    ]),
    trigger('showText', [
      state(
        'hide',
        style({
          height: 0,
          overflow: 'hidden',
        })
      ),
      state(
        'show',
        style({
          height: '*',
          overflow: 'hidden',
        })
      ),
      transition('hide <=> show', animate('300ms ease-in-out')),
    ]),
    trigger('hideDots', [
      state(
        'hide',
        style({
          opacity: 0,
        })
      ),
      state(
        'show',
        style({
          opacity: '*',
        })
      ),
      transition('hide => show', animate('300ms ease-in-out')),
      transition('show => hide', animate('300ms ease-in-out')),
    ]),
    trigger('showTextMobile', [
      state(
        'hide',
        style({
          height: 0,
          margin: 0,
        })
      ),
      state(
        'show',
        style({
          height: '*',
          margin: '*',
        })
      ),
      transition('hide => show', animate('300ms ease-in-out')),
      transition('show => hide', animate('300ms ease-in-out')),
    ]),
    trigger('slideInOut', [
      state('outright', style({ transform: `translateX(100%)` })),
      state('outleft', style({ transform: `translateX(-100%)` })),
      transition('void=>inleft', [style({ transform: `translateX(0)` })]),
      transition('void=>outleft', [style({ transform: `translateX(-100%)` })]),

      transition('*=>inright', [
        style({ transform: `translateX(-100%)` }),
        animate('260ms ease-out', style({ transform: `translateX(0)` })),
      ]),
      transition('*=>inleft', [
        style({ transform: `translateX(100%)` }),
        animate('260ms ease-out', style({ transform: `translateX(0)` })),
      ]),
      transition('*=>outleft', [
        animate('260ms ease-in', style({ transform: `translateX(-100%)` })),
      ]),
      transition('*=>outright', [
        animate('260ms ease-in', style({ transform: `translateX(100%)` })),
      ]),
      ,
    ]),
  ],
})
export class ServicesForYouComponent implements OnInit {
  @Input() items$: Observable<any[]>;

  public items: any[];
  public services: any[];

  public isMobile = false;
  public currentIndex = 0;
  public itemsPerPage = 4;
  public dotRange = [];
  public isHoveredIndex: number | null = null;
  public expandedIndex: number | null = null;
  public isLastServiceExpanded: boolean = false;
  public direction: string;
  visibilityStates: { [key: number]: string } = {};

  constructor(
    private sanitizer: DomSanitizer,
    private readonly screenService: ScreenSizeService
  ) {}

  ngOnInit() {
    this.screenService
      .isMobile()
      .subscribe((isMobile) => (this.isMobile = isMobile));

    this.items$
      .pipe(
        map((items) => {
          items.forEach((item, index) => {
            if (item.image) {
              item.image = this.sanitizer.bypassSecurityTrustResourceUrl(
                item.image
              );
              item.services = item.services.slice(0, 3);
            }
          });
          return items;
        })
      )
      .subscribe((items) => {
        this.items = items;
        this.getCurrentItems();
        this.getDotRange();
      });
    this.getDotRange();
  }

  getCurrentItems() {
    const start = this.currentIndex * this.itemsPerPage;
    const end = Math.min(start + this.itemsPerPage, this.items.length);
    this.services = this.items.slice(start, end);
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  goToIndex(index: number) {
    this.direction = index > this.currentIndex ? 'outleft' : 'outright';
    this.currentIndex = index;

    setTimeout(() => {
      this.getCurrentItems();
      this.direction = this.direction == 'outright' ? 'inright' : 'inleft';
    }, 280);
  }

  getDotRange(): void {
    this.items$.subscribe((items) => {
      const dotCount = Math.ceil(items.length / this.itemsPerPage);
      this.dotRange = Array(dotCount)
        .fill(0)
        .map((_, index) => index);
    });
  }

  onItemHover(index: number) {
    this.isHoveredIndex = index;
    this.setVisibilityWithDelay(index, false);
  }

  onItemLeave(index: number) {
    this.isHoveredIndex = null;
    this.setVisibilityWithDelay(index, true);
  }
  setVisibilityWithDelay(index: number, isVisible: boolean) {
    setTimeout(() => {
      this.visibilityStates[index] = isVisible ? 'visible' : 'hidden';
    }, 150);
  }

  toggleMobileService(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }
}
