/**
 * @license
 * Copyright 2022 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
/**
 * Joins a RenderResult into a string
 */
export const collectResult = async result => {
  let value = '';
  for (const chunk of result) {
    value += typeof chunk === 'string' ? chunk : await collectResult(await chunk);
  }
  return value;
};
/**
 * Joins a RenderResult into a string synchronously.
 *
 * This function throws if a RenderResult contains a Promise.
 */
export const collectResultSync = result => {
  let value = '';
  for (const chunk of result) {
    if (typeof chunk === 'string') {
      value += chunk;
    } else {
      throw new Error('Promises not supported in collectResultSync. ' + 'Please use collectResult.');
    }
  }
  return value;
};
