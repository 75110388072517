import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ScreenSizeService } from 'src/app/services/screen-size.service';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.css'],
})
export class HeaderHomeComponent implements OnInit {
  public isMobile = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly screenService: ScreenSizeService
  ) {}

  ngOnInit() {
    this.screenService
      .isMobile()
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  scrollToServices() {
    if (isPlatformBrowser(this.platformId)) {
      const servicesElement = document.getElementById('servicesComponent');
      if (servicesElement) {
        servicesElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
