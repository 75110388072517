<section>
  <div class="column-selector show-on-mobile">
    <div
      class="column-selector-item"
      *ngFor="let tier of priceByTier"
      (click)="selectedTier = tier.name"
      [ngClass]="{ selected: selectedTier === tier.name }"
    >
      <span class="column-selector-item-name">{{ tier.name }}</span>
      <span class="column-selector-item-price">{{ tier.price }}€</span>
    </div>
  </div>
  <table>
    <thead class="hide-on-mobile">
      <tr class="header-row">
        <th></th>
        @if (priceByTier) {
        <th>
          <app-productcategory-agency-table-th
            [tier]="priceByTier[0]"
          ></app-productcategory-agency-table-th>
        </th>
        <th>
          <app-productcategory-agency-table-th
            [tier]="priceByTier[1]"
          ></app-productcategory-agency-table-th>
        </th>
        <th>
          <app-productcategory-agency-table-th
            [tier]="priceByTier[2]"
          ></app-productcategory-agency-table-th>
        </th>
        }
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let feature of getKeys()">
        <td class="feature-description">
          {{ feature }}
          <span class="info-in-mobile"> {{ tableInfoForMobile(feature) }}</span>
        </td>
        <td
          class="checkmark-container"
          [ngClass]="{ 'hide-on-mobile': selectedTier !== 'starter' }"
        >
          <div class="cell-container">
            <img
              *ngIf="featuresByTier[feature].includes('starter')"
              class="check-img"
              src="assets/img/productcategory/checkmark.svg"
            />
            <span class="cell-text">{{ tableInfo(feature, "starter") }}</span>
          </div>
        </td>
        <td
          class="checkmark-container"
          [ngClass]="{ 'hide-on-mobile': selectedTier !== 'pro' }"
        >
          <div class="cell-container">
            <img
              *ngIf="featuresByTier[feature].includes('pro')"
              class="check-img"
              src="assets/img/productcategory/checkmark.svg"
            />
            <span class="cell-text">{{ tableInfo(feature, "pro") }}</span>
          </div>
        </td>
        <td
          class="checkmark-container"
          [ngClass]="{ 'hide-on-mobile': selectedTier !== 'premium' }"
        >
          <div class="cell-container">
            <img
              *ngIf="featuresByTier[feature].includes('premium')"
              class="check-img"
              src="assets/img/productcategory/checkmark.svg"
            />
            <span class="cell-text">{{ tableInfo(feature, "premium") }}</span>
          </div>
        </td>
      </tr>
  
      @if (tableFooterType === 'autonomos') {
      <tr class="footerRow">
        <td class="first">
          <div class="description-footer long-footer">
            Descuento <b>para clientes de tu gestoría</b>
          </div>
          <!--<div class="description-footer">
            Descuento 
          </div>-->
        </td>
  
        <td
          class="first"
          [ngClass]="{ 'hide-on-mobile': selectedTier !== 'starter' }"
        >
          <div class="footerInfo">5%</div>
        </td>
        <td
          class="first"
          [ngClass]="{ 'hide-on-mobile': selectedTier !== 'pro' }"
        >
          <div class="footerInfo">10%</div>
        </td>
        <td
          class="first"
          [ngClass]="{ 'hide-on-mobile': selectedTier !== 'premium' }"
        >
          <div class="footerInfo">15%</div>
        </td>
      </tr>
      <tr class="footerRow">
        <td>
          <div class="description-footer">Descuento <b>para empleados</b></div>
        </td>
        <td [ngClass]="{ 'hide-on-mobile': selectedTier !== 'starter' }">
          <div class="footerInfo">10%</div>
        </td>
        <td [ngClass]="{ 'hide-on-mobile': selectedTier !== 'pro' }">
          <div class="footerInfo">10%</div>
        </td>
        <td [ngClass]="{ 'hide-on-mobile': selectedTier !== 'premium' }">
          <div class="footerInfo">10%</div>
        </td>
      </tr>
      } @if (tableFooterType === 'sociedades') {
      <tr class="footerRow">
        <td class="first">
          <div class="description-footer">
            Alta de autónomos <b>sin gestoría</b>
          </div>
        </td>
        <td class="first hide-on-mobile"><div class="hide">.</div></td>
        <td class="first"><div>40€</div></td>
        <td class="first hide-on-mobile"><div class="hide">.</div></td>
      </tr>
      <tr class="footerRow">
        <td>
          <div class="description-footer">
            Alta de autónomos <b>con gestoría</b>
          </div>
        </td>
        <td class="hide-on-mobile"><div class="hide">.</div></td>
        <td><div>Gratis</div></td>
        <td class="hide-on-mobile"><div class="hide">.</div></td>
      </tr>
      }
    </tbody>
  </table>
  <a class="empieza-ya show-on-mobile" (click)="openGenericProductModal()">
    <span>empieza ya</span>
    <div>
      <span>{{ selectedTier }}</span>
      <span class="price">{{ getPriceByTier(selectedTier) }}€</span>
    </div>
  </a>
</section>
