import {
  isPlatformBrowser,
  registerLocaleData,
  CommonModule,
} from '@angular/common';
import {
  HttpResponse,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import locale from '@angular/common/locales/es';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Inject,
  NgModule,
  PLATFORM_ID,
  isDevMode,
  makeStateKey,
  TransferState,
  LOCALE_ID,
  Injectable,
  afterNextRender,
} from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { TokenInterceptor } from 'src/token.interceptor';
import { AppComponent } from './app.component';
import {
  appRoutes,
  appRoutingProviders,
  homeOutletRoutes,
  routing,
} from './app.routing';
import { ErrorComponent } from './apptemplate/error/error.component';
import { HeaderComponent } from './apptemplate/header/header.component';
import { DjangoErrorHandler } from './django.error';
import { AuthService } from './services/auth.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AlertService } from './services/notificaciones.service';
import { FaqsComponent } from './components/faqs/faqs.component';
import { StepsComponent } from './components/steps/steps.component';
import { ProductComponent } from './apptemplate/product/product.component';
import { HomeOutletComponent } from './apptemplate/home-outlet/home-outlet.component';
import { ProductsServices } from './services/products.services';
import {
  STATE_KEY_PREFIX,
  TransferHttpResponseInterceptor,
} from 'src/transfer-http-response-interceptor.service';
import { CachingInterceptor } from 'src/caching-interceptor';
import { RequestCacheService } from './services/request-cache.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Route,
  Router,
} from '@angular/router';
import { ProductResolverUrl } from './resolvers/product.resolver';
import {
  Product,
  isProductVisible,
  ProductDescription,
} from './models/products';
import { SharedHomeModule } from './shared-home/shared-home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagoconfirmacionComponent } from './contracts/pagoconfirmacion/pagoconfirmacion.component';
import { InitiateAuthenticationPaymentComponent } from './contracts/initiate-authentication-payment/initiate-authentication-payment.component';
import { GifComponent } from './gif/gif.component';
import { CoockiesPopupComponent } from './apptemplate/coockies-popup/coockies-popup.component';
import { ProductIncludesComponent } from './components/product-includes/product-includes.component';
import { ProductSectionsComponent } from './components/product-sections/product-sections.component';
import { ProductArticlesComponent } from './components/product-articles/product-articles.component';
import { ProductReviewsComponent } from './components/product-reviews/product-reviews.component';
import { PostComponent } from './components/posts/post.component';
import { FooterComponent } from './apptemplate/footer/footer.component';
import { SharedModule } from './shared/shared.module';

import { ProductTierComponent } from './components/product-tier/product-tier.component';
import { ProductOptionsComponent } from './components/product-options/product-options.component';
import { HttpCrmRequestInterceptorShowProgressBarInterceptor } from './backoffice/shared/interceptors/http-request-interceptor-show-progress-bar.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HomeReviewsComponent } from './components/home-reviews/home-reviews.component';
import { StarsHomeComponent } from './components/stars-home/stars-home.component';
import { OpinionsHomeComponent } from './components/opinions-home/opinions-home.component';
import { StarsComponent } from './components/stars/stars.component';
import { ReviewHomeComponent } from './components/review-home/review-home.component';
import { HomeUpdateComponent } from './apptemplate/home/home.component';
import { WhyEnleyComponent } from './components/why-enley/why-enley.component';
import { WhyEnleyReasonComponent } from './components/why-enley-reason/why-enley-reason.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { CanDoComponent } from './components/can-do/can-do.component';
import { ServicesComponent } from './components/services/services.component';
import { ServicesForYouComponent } from './components/services-for-you/services-for-you.component';
import { ArticlesHomeComponent } from './components/articles-home/articles-home.component';
import { FaqsHomeComponent } from './components/faqs-home/faqs-home.component';
import { HeaderHomeComponent } from './components/header-home/header-home.component';
import { ProductCategoryComponent } from './apptemplate/productcategory/productcategory.component';
import { ProductCategorySubcategoryComponent } from './apptemplate/productcategory/subcategories/subcategory/subcategory.component';
import { ProductCategorySubcategoriesComponent } from './apptemplate/productcategory/subcategories/subcategories.component';
import { ProductCategoryGenericProductModalComponent } from './apptemplate/productcategory/subcategories/subcategory/generic-product-modal/generic-product-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ProductCategoryAgencyAutonomosComponent } from './apptemplate/productcategory/subcategories/subcategory/agency-content/autonomos/autonomos.component';
import { ProductCategoryAgencyLaboralComponent } from './apptemplate/productcategory/subcategories/subcategory/agency-content/laboral/laboral.component';
import { ProductCategoryAgencySociedadesComponent } from './apptemplate/productcategory/subcategories/subcategory/agency-content/sociedades/sociedades.component';
import { ProductCategoryAgencyTableComponent } from './apptemplate/productcategory/subcategories/subcategory/agency-content/table/table.component';
import { ProductCategoryAgencyTableThComponent } from './apptemplate/productcategory/subcategories/subcategory/agency-content/table/th/th.component';
import { ProductSubcategoryComponent } from './apptemplate/productcategory/subcategories/subcategory/product/product.component';
declare var Hammer: any;

import * as Sentry from '@sentry/angular';

registerLocaleData(locale, 'es');
// making hammer config (3)
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    super();
    afterNextRender(() => {
      this.overrides = <any>{
        swipe: { direction: Hammer.DIRECTION_ALL },
      };
    });
  }
}

const CATEGORY_DECLARATIONS = [
  ProductCategoryComponent,
  ProductCategorySubcategoryComponent,
  ProductCategorySubcategoriesComponent,
  ProductCategoryGenericProductModalComponent,
  ProductCategoryAgencyAutonomosComponent,
  ProductCategoryAgencyLaboralComponent,
  ProductCategoryAgencySociedadesComponent,
  ProductCategoryAgencyTableComponent,
  ProductCategoryAgencyTableThComponent,
  ProductSubcategoryComponent,
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ErrorComponent,
    FaqsComponent,
    StepsComponent,
    ProductComponent,
    HomeOutletComponent,
    PagoconfirmacionComponent,
    InitiateAuthenticationPaymentComponent,
    GifComponent,
    CoockiesPopupComponent,
    ProductIncludesComponent,
    ProductSectionsComponent,
    ProductArticlesComponent,
    ProductReviewsComponent,
    PostComponent,
    FooterComponent,
    ProductTierComponent,
    ProductOptionsComponent,
    HomeReviewsComponent,
    StarsHomeComponent,
    OpinionsHomeComponent,
    StarsComponent,
    ReviewHomeComponent,
    HomeUpdateComponent,
    WhyEnleyComponent,
    WhyEnleyReasonComponent,
    CanDoComponent,
    HowItWorksComponent,
    ServicesComponent,
    ServicesForYouComponent,
    ArticlesHomeComponent,
    FaqsHomeComponent,
    HeaderHomeComponent,
    ...CATEGORY_DECLARATIONS,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    routing,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    SharedHomeModule,
    SharedModule,
    BrowserAnimationsModule,
    //tracking needs it
    ReactiveFormsModule,
    HammerModule,
  ],
  providers: [
    appRoutingProviders,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferHttpResponseInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCrmRequestInterceptorShowProgressBarInterceptor,
      multi: true,
    },
    AuthService,
    AlertService,
    DjangoErrorHandler,
    ProductsServices,
    RequestCacheService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
    productServices: ProductsServices,
    transferState: TransferState
  ) {
    if (isPlatformBrowser(platformId)) {
      const key = makeStateKey<HttpResponse<Product[]>>(
        STATE_KEY_PREFIX + environment.apiUrl + 'product/'
      );

      let products: Product[];
      const transferedResponse = transferState.get(key, null);
      if (transferedResponse != null) {
        products = transferedResponse.body;
      } else {
        var request = new XMLHttpRequest();
        request.open('GET', environment.apiUrl + 'product/', false);
        request.setRequestHeader('Accept-Language', 'es');

        request.send(null);
        products = JSON.parse(request.responseText);
      }
      this.configProducts(products);
    } else {
      productServices
        .getAvailableProducts()
        .subscribe((products) => this.configProducts(products));
    }
  }

  configProducts(products: Product[]): void {
    const routes = new Map<string, Route>();
    for (let product of products) {
      if (!isProductVisible(product)) continue;

      if (isDevMode() && routes.has(product.url))
        console.warn(`Repeated product URL: ${product.url}`);
      routes.set(product.url, {
        path: product.url,
        component: ProductComponent,
        resolve: { data: ProductResolverUrl },
      });
    }

    homeOutletRoutes.unshift(...Array.from(routes.values()));
    this.router.resetConfig(appRoutes);
  }
}
