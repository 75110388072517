@if (category) {
<div class="category-header">
  <h1 class="category-header__main-title">{{ category.name }}</h1>
  <h2 class="category-header__subtitle">{{ category.description }}</h2>
  <iframe
    class="clouds-iframe"
    width="1400"
    height="100%"
    frameborder="0"
    src="assets/img/category_clouds.html"
    scrolling="no"
  ></iframe>
  <iframe
    id="category-iframe"
    class="category-iframe"
    *ngIf="htmlContent"
    frameborder="0"
    [srcdoc]="htmlContent"
    width="1400"
    [style.height.px]="canvasHeight"
    scrolling="no"
  ></iframe>
</div>

<div
  class="container-products-list"
  *ngIf="category.products && category.products.length > 0"
>
  <app-productcategory-product
    [products]="category.products"
  ></app-productcategory-product>
</div>
<app-productcategory-subcategories
  [subcategories]="subcategories"
  *ngIf="category.subcategories && category.subcategories.length > 0"
></app-productcategory-subcategories>
<app-articles-home
  [articles]="articles"
  *ngIf="articles.length > 0"
></app-articles-home>
<app-faqs-home
  [faqs]="category.faqs"
  [phone_number]="654315872"
  *ngIf="category.faqs.length > 0"
  backgroundColor="gray-category"
></app-faqs-home>
<app-home-reviews
  backgroundColor="white"
  cardColor="grey"
  [title]="category.review_title"
  [reviewsInput]="reviews"
></app-home-reviews>
<div class="footer-space"></div>
}
