import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { ProductSubCategory } from 'src/app/models/productcategory';
import { SlideInOutAnimation, RotateAnimation } from '../../animations';
import { Product } from 'src/app/models/products';
import { MatDialog } from '@angular/material/dialog';
import { ProductCategoryGenericProductModalComponent } from './generic-product-modal/generic-product-modal.component';
import { Overlay } from '@angular/cdk/overlay';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioServices } from 'src/app/services/usuario.services';
import { AuthService } from 'src/app/services/auth.service';
import { User } from 'src/app/models/user';
import { Location, ViewportScroller } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-productcategory-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrl: './subcategory.component.css',
  providers: [UsuarioServices, AuthService],
  animations: [SlideInOutAnimation, RotateAnimation],
})
export class ProductCategorySubcategoryComponent
  implements OnInit, AfterViewInit
{
  @Input() subcategory: ProductSubCategory = null;

  @ViewChild('headerRef') header: ElementRef;
  public isLoggedIn: boolean = false;
  public user: User = undefined;
  public htmlContent: SafeHtml = null;
  public svgContent: any = null;
  public isOpened: boolean = false;

  @Output() expandedCollapsed = new EventEmitter<Boolean>();

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private userService: UsuarioServices,
    private authService: AuthService,
    private viewportScroller: ViewportScroller,
    private renderer: Renderer2
  ) {}

  animationState = 'out';

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.getUser();
    }
  }

  ngAfterViewInit(): void {
    console.log;
    this.activatedRoute.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        const element = this.renderer.selectRootElement(`#${fragment}`, true);
        if (element) {
          setTimeout(() => {
            this.viewportScroller.setOffset([0, 80]);
            this.viewportScroller.scrollToAnchor(fragment);
            this.expandSectionById(fragment);
          }, 300);
        }
      }
    });
  }

  getUser() {
    this.userService.getUser().subscribe((user) => {
      this.user = user;
    });
  }

  handleProductClick(event: Event, product: Product) {
    event.preventDefault();
    if (product.url) {
      this.router.navigate([product.url]);
    } else {
      this.dialog.open(ProductCategoryGenericProductModalComponent, {
        panelClass: 'dialog-with-overflow',
        maxWidth: '100vw',
        maxHeight: '70vh',
        data: { product: product, user: this.user },
      });
    }
  }

  expandCollapse() {
    this.animationState = this.animationState === 'out' ? 'in' : 'out';
    this.isOpened = !this.isOpened;

    if (this.animationState === 'out') return;

    const headerElement = this.header.nativeElement;
  }

  slugify(name: string): string {
    return name
      .toLowerCase()
      .trim()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  expandSectionById(id: string) {
    if (this.slugify(this.subcategory.name) === id) {
      this.expandCollapse();
    }
  }
}
