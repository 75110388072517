import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  constructor(private breakpointObserver: BreakpointObserver) { }

  getBreakpointObservables(): Observable<BreakpointState> {
    return this.breakpointObserver.observe([
      '(max-width: 599px)', 
      '(min-width: 600px)', 
    ]);
  }

  isMobile(): Observable<boolean> {
    return this.breakpointObserver.observe('(max-width: 599px)').pipe(
      map(state => state.matches)
    );
  }

  isDesktop(): Observable<boolean> {
    return this.breakpointObserver.observe('(min-width: 600px)').pipe(
      map(state => state.matches)
    );
  }


}


