<div class="container">
  <span class="description"
    >Administración de nóminas, contratos, altas y bajas en la Seguridad Social
    y más. Con nuestra ayuda podrás optimizar la gestión diaria de tu personal.
    Elige la opción que más se ajusta a tus necesidades y déjanos a nosotros los
    trámites.
  </span>
  <div>
    <div class="table-container">
      <div class="list-container">
        <span class="incluye"> Incluye </span>
        <ul class="list">
          <li>Apertura del Código Cuenta de Cotización (C.C.C.)</li>
          <li>Presentación de los modelos 111 y 190</li>
          <li>Confección y presentación de los seguros sociales</li>
          <li>Contrato de trabajo estandarizado</li>
          <li>Tramitación de partes de accidentes de trabajo</li>
          <li>Asesoramiento laboral</li>
          <li>Encuadramiento de categoría de convenio colectivo</li>
          <li>Confección de nóminas mensuales</li>
        </ul>
      </div>
      <div class="Line-63"></div>
      <table>
        <thead>
          <tr class="header-row">
            <th>EMPLEADOS</th>
            <th>PRECIO TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pack of packs">
            <td>{{ this.get_employees_text(pack) }}</td>
            <td>{{ this.get_price(pack) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="me-interesa-wrapper"><button (click)="openGenericProductModal()" class="me-interesa">me interesa</button></div>
  </div>
</div>
