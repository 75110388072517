/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { setBase } from '@enley-es/web-components/es/utils/asset';
import { HttpErrorResponse } from '@angular/common/http';
import 'hammerjs';
import { browserTracingIntegration, init } from '@sentry/angular';

setBase('./web-components-assets/');

if (environment.name === 'PROD' || environment.name === 'TEST') {
  enableProdMode();

  init({
    dsn: environment.sentryDsn,
    integrations: [browserTracingIntegration()],
    tracePropagationTargets: ['localhost', 'https://yourserver.io/api'],
    tracesSampleRate: 0.2,

    environment: environment.name,
  });
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
