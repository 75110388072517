import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-outlet',
  templateUrl: './home-outlet.component.html',
  styleUrls: ['./home-outlet.component.css'],
})
export class HomeOutletComponent implements OnInit  {
  isPreformActive = false;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(() => {
      const currentPath = this.router.routerState.snapshot.url.split('/')[1];
      this.isPreformActive = currentPath === 'formulario-registro-ind-b';
    });
  }
}
