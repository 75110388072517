import {
  Component,
  Inject,
  Input,
  PLATFORM_ID,
  OnDestroy,
  OnInit,
  ViewChildren,
  ViewChild,
  QueryList,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ScreenSizeService } from 'src/app/services/screen-size.service';

@Component({
  selector: 'app-why-enley-reason',
  templateUrl: './why-enley-reason.component.html',
  styleUrls: ['./why-enley-reason.component.css'],
})
export class WhyEnleyReasonComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  public slideIndex = 1;
  private slideInterval: any;
  public isMobile = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly screenService: ScreenSizeService
  ) {}
  @ViewChildren('dot') dots!: QueryList<ElementRef<HTMLElement>>;
  @ViewChild('carouselContainer') carouselContainer!: ElementRef<HTMLElement>;

  ngOnInit() {
    this.screenService
      .isMobile()
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  ngAfterViewInit() {
    this.showSlides(this.slideIndex);
  }

  ngOnDestroy() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }

  currentSlide(n: number) {
    this.showSlides((this.slideIndex = n));
  }

  nextSlide() {
    this.showSlides((this.slideIndex += 1));
  }

  previousSlide() {
    this.showSlides((this.slideIndex -= 1));
  }

  showSlides(n: number) {
    if (n > 4) {
      this.slideIndex = 4;
    }
    if (n < 1) {
      this.slideIndex = 1;
    }

    const offset = (this.slideIndex - 1) * 100;

    this.dots.forEach((dot) => {
      dot.nativeElement.classList.remove('active');
    });
    if (this.carouselContainer) {
      const container = this.carouselContainer.nativeElement;
      container.style.transform = `translateX(-${offset}%)`;
    }

    this.dots.forEach((dot, index) => {
      if (index === this.slideIndex - 1) {
        dot.nativeElement.classList.add('active');
      }
    });
  }
}
