import { _$LE as t } from "./lit-element.js";
/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
const e = {
  attributeToProperty: t._$AK,
  changedProperties: t._$AL
};
export { e as _$LE };
