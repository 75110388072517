<section
  class="faqs"
  [ngClass]="{
    'background-gray': backgroundColor === 'gray',
    'background-gray-category': backgroundColor === 'gray-category'
  }"
>
  <div class="faqs__container">
    <h2 class="faqs__main-title">Preguntas frecuentes</h2>
    <div class="faqs__wrapper" id="accordionFlushExample">
      <div
        class="accordion-item"
        *ngFor="let faq of faqs; index as i; let first = first"
      >
        <h3 id="flush-headingOne{{ i }}">
          <button
            id="button-{{ i }}"
            class="accordion-button"
            [ngClass]="[first ? '' : 'collapsed']"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#flush-collapseOne' + i"
            [aria-expanded]="first ? 'true' : 'false'"
          >
            <p class="acordeon-question">
              {{ faq.title }}
            </p>
          </button>
        </h3>
        <div
          id="flush-collapseOne{{ i }}"
          class="accordion-collapse collapse"
          [ngClass]="[first ? 'show' : '']"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="acordeon-answer" [innerHTML]="faq.safe_text_html"></div>
        </div>
      </div>
    </div>
  </div>
</section>
